// 原创作者模块
import axios from '../axios'
import qs from "qs";

export default {
    state: {
        templateList: [], // 模板列表
        currentPage: 1, // 当前页
        pageCount: 1, // 总页数

        templateDetail: {}
    },
    mutations: {
        setTemplateList(state, { list = [], current = 1, count = 1 }) { // 写入创意作品列表
            state.templateList = list;
            state.currentPage = current;
            state.pageCount = count;
        },
        setTemplateDetail(state, arg) {
            state.templateDetail = arg;
        }
    },
    actions: {
        getTemplateList({ commit }, userID) { // 获取创意作品列表
            commit("handleLoading", true);
            let url = `usercenter/templetList?userID=${userID}`;
            axios.get(url).then(res => {
                const data = res.data;
                if (data.code == 200) {
                    commit('setTemplateList', {
                        list: data.result.list,
                        current: data.result.current_page,
                        count: data.result.page_count
                    })
                }
            }).catch(err => {
                console.log(`获取创意作品列表接口异常：${err}`);
            }).finally(() => commit('handleLoading', false))
        },
        handleDelTemplate({ commit }, {userID, worksID}) { // 删除原创作品
            commit("handleLoading", true);
            let url = 'usercenter/deleteTemplet',
                data = qs.stringify({
                    userID,
                    worksID
                });
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    const data = res.data;
                    if (data.code == 200) {
                        resolve({
                            type: 'success',
                            msg: data.message,
                            result: data.result
                        })
                    } else {
                        resolve({
                            type: 'error',
                            msg: data.message,
                            result: data.result
                        })
                    }
                }).catch(err => {
                    reject(err)
                    console.log(`删除原创作品接口异常：${err}`);
                }).finally(() => commit('handleLoading', false))
            })
        },
        handleTemplateDetail({ commit }, arg) { // 添加/编辑 创意作品
            commit("handleLoading", true);
            let {
                userID,
                worksID = '',
                name,
                images,
                preview,
                type,
                style,
                price,
                unit,
                specList,
                priceList
            } = arg,
                url = '',
                data = qs.stringify({
                    userID,
                    worksID,
                    name,
                    images,
                    preview,
                    type,
                    style,
                    price,
                    unit,
                    specList,
                    priceList
                })
            url = worksID == '' ? 'usercenter/addTemplet' : 'usercenter/editTemplet';
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    const data = res.data;
                    if (data.code == 200) {
                        resolve({
                            type: 'success',
                            msg: data.message,
                            result: data.result
                        })
                    } else {
                        resolve({
                            type: 'error',
                            msg: data.message,
                            result: data.result
                        })
                    }
                }).catch(err => {
                    reject(err)
                    console.log(`添加/编辑 创意作品接口异常：${err}`);
                }).finally(() => commit('handleLoading', false))
            })
        },
        getTemplateDetail({ commit }, {userID, worksID}) { // 获取创意作品详情
            commit("handleLoading", true);
            let url = `usercenter/templetDetail?userID=${userID}&worksID=${worksID}`;
            axios.get(url)
                .then(res => {
                    const data = res.data;
                    if (data.code == 200) {
                        commit('setTemplateDetail', data.result)
                    }
                })
                .catch(err => {
                    console.log(`获取创意作品详情接口异常：${err}`);
                }).finally(() => commit('handleLoading', false))
        }
    }
}