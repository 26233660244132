// 商品列表获取
import axios from '../axios'

export default {
    state: {
        // 商品副列表
        sublist: [],
        // 商品主列表
        list: [],
        // 页码
        page: {},
        
        // 筛选条件
        hotwords: [], // 热门搜索词
        clazz: [], // 地址
        price: [], // 价格
        style: [], // 风格
        // 地区
        // region: [],
        // 类别 & 品牌
        // type: [],
        // 选中地区
        // active_region: [],
        // 选中类别
        // active_type: [],
        // 选中排序方式
        // active_sort: "",
        // 价格下限
        // lower_limit: "",
        // 价格上限
        // upper_limit: "",

        // 影像服务
        // 拍摄主题
        photograph_theme: [],
        photograph_theme_campus: [],
        photograph_theme_personal: [],
        photograph_theme_biz: [],
        photograph_theme_video: [],
        photograph_theme_classical: [],
        // 视频主题
        video_theme: [],
        // 个人怀旧
        nostalgic_theme: [],

        // 影像课堂
        video_type: [], // 影像课堂分类
    },
    mutations: {
        // 次要列表参数写入
        writeSublist(state, arg){
            state.sublist = arg;
        },
        // 主要列表参数写入
        writeList(state, arg){
            state.list = arg.list;
            state.page = arg.page;
        },

        // 筛选条件写入
        setShareFilter(state, arg){
            state.hotwords = arg.hotwords || [];
            state.price = arg.price || [];
            state.style = arg.style || [];
            state.clazz = arg.clazz || [];
        },
        // writeCondition(state, arg){
        //     state.region = arg.region;
        //     state.type = arg.type;
        // },
        // 选中地区写入
        // writeActiveRegion(state, arg){
        //     state.active_region = arg;
        // },
        // 选中类别写入
        // writeActiveType(state, arg){
        //     state.active_type = arg;
        // },
        // 选中排序方式写入
        // writeActiveSort(state, arg){
        //     state.active_sort = arg;
        // },
        // 价格下限写入
        // writeLowerLimit(state, arg){
        //     state.lower_limit = arg;
        // },
        // 价格上限写入
        // writeUpperLimit(state, arg){
        //     state.upper_limit = arg;
        // },

        // 影像服务列表
        writePhotograph(state, arg){
            state.photograph_theme = arg.photograph;
            state.photograph_theme_campus = arg.campus;
            state.photograph_theme_personal = arg.personal;
            state.photograph_theme_biz = arg.biz;
            state.video_theme = arg.video;
            state.nostalgic_theme = arg.nostalgic;
            state.photograph_theme_video = arg.video;
            state.photograph_theme_classical = arg.classical;
        },

        // 影响课堂
        setVideoType(state, arg){ // 影像课堂分类
            state.video_type = arg;
        },

        // 清除列表数据
        clearSublits(state){
            state.sublist = [];
        },
        clearList(state){
            state.list = [];
            state.page = {};
        },
        // 清除筛选条件数据
        clearCondition(state){
            state.region = [];
            state.type = [];
        },

        // 清除选中地区数据
        clearActiveRegion(state){
            state.active_region = [];
        },
        // 清除选中类别
        clearActiveType(state){
            state.active_type = [];
        },
        // 清除选中排序方式
        clearActiveSort(state){
            state.active_sort = "";
        },
        // 清除价格筛选
        clearPriceLimit(state){
            state.lower_limit = "";
            state.upper_limit = "";
        },

        // 清除影像服务数据
        clearPhotograph(state){
            state.photograph_theme = [];
            state.video_theme = [];
            state.nostalgic_theme = [];
        },
    },
    actions: {
        // 摄影爱好者
        getCameraAmateur({ commit }){
            // 清空参数
            commit("clearSublits");
            // 发起请求
            let url = "lease/leaseAmateur";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("writeSublist", res.data.amateur);
                };
            }).catch(() => {})
        },
		
        // 相机列表
        getCameraList({ commit }, arg){
            // 清空参数
            commit("clearList");
            commit("handleLoading", true);
            let { search, area, style, clazz, price, page = 1 } = arg,
            url = "lease/leaseList?search=" + search + "&area=" + area + "&style=" + style + "&clazz=" + clazz + "&price=" + price + "&page=" + page;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 1){
                    commit("writeList", {
                        list: res.data.goods_list, 
                        page: res.data.page
                    });
                };
            }).catch(() => {});
        },
		// 场地列表
		getFieldList({ commit }, arg){
		    // 清空数据
		    commit("clearList");
		    commit("handleLoading", true);
		    let { search, area, style, clazz, price, page = 1 } = arg,
		    url = "field/fieldList?search=" + search + "&area=" + area + "&style=" + style + "&clazz=" + clazz + "&price=" + price + "&page=" + page;
		    axios.get(url).then((res) => {
		        commit("handleLoading", false);
		        if(res.data.code == 1){
		            commit("writeList", {
		                list: res.data.field_list, 
		                page: res.data.page
		            });
		        };
		    }).catch(() => {});
		},
        // 筛选条件
        getLeaseCondition({ commit }){
            // 清空筛选条件
            commit("setShareFilter", {});
            // 发起请求
            let url = "lease/leaseCondition";
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setShareFilter", res.data.result);
                };
            }).catch(() => {});
        },

        // 影像服务
        getPhotograph({ commit }){
            // 发起请求
            let url = "photograph/photograph";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("writePhotograph", {
                        photograph: res.data.photograph_theme,
                        campus: res.data.photograph_theme_campus,
                        personal: res.data.photograph_theme_personal,
                        biz: res.data.photograph_theme_biz,
                        video: res.data.video_theme,
                        nostalgic: res.data.nostalgic_theme,
                        video: res.data.photograph_theme_video,
                        classical: res.data.photograph_theme_classical,
                    })
                };
            }).catch(() => {});
        },

        // 优秀摄影师
        getLensmanExcellent({ commit }){
            // 清空数据
            commit("clearSublits");
            // 获取数据
            let url = "lensman/lensmanExcellent";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("writeSublist", res.data.lensman_excellent);
                };
            }).catch(() => {});
        },
        // 摄影师列表
        getLensmanList({ commit }, arg){
            // 清空数据
            commit("clearList");
            commit("handleLoading", true);
            let { area, style, filter, page = 1 } = arg,
            url = "lensman/lensmanList?filter=" + filter + "&area=" + area + "&style=" + style + "&page=" + page;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 1){
                    commit("writeList", {
                        list: res.data.lensman_list, 
                        page: res.data.page
                    });
                };
            }).catch(() => {});
        },
        // 筛选条件
        getLensmanCondition({ commit }){
            // 清空筛选条件
            commit("clearCondition");
            // 发起请求
            let url = "lensman/lensmanCondition";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    // commit("writeCondition", res.data);
                };
            }).catch(() => {});
        },

        // 优秀化妆师
        getDresserExcellent({ commit }){
            // 清空数据
            commit("clearSublits");
            // 获取数据
            let url = "dresser/dresserExcellent";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("writeSublist", res.data.dresser_excellent);
                };
            }).catch(() => {});
        },
        // 化妆师列表
        getDresserList({ commit }, arg){
            // 清空数据
            commit("clearList");
            commit("handleLoading", true);
            let { area, style, filter, page = 1 } = arg,
            url = "dresser/dresserList?filter=" + filter + "&area=" + area + "&style=" + style + "&page=" + page;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 1){
                    commit("writeList", {
                        list: res.data.dresser_list, 
                        page: res.data.page
                    });
                };
            }).catch(() => {});
        },
        // 筛选条件
        getDresserCondition({ commit }){
            // 清空筛选条件
            commit("clearCondition");
            // 发起请求
            let url = "dresser/dresserCondition";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    // commit("writeCondition", res.data);
                };
            }).catch(() => {});
        },

        // 精选场地
        getFieldHandpick({ commit }){
            // 清除数据
            commit("clearSublits");
            // 发起请求
            let url = "field/fieldHandpick";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("writeSublist",res.data.field_handpick);
                }
            }).catch(() => {});
        },
		
     
        // 筛选条件
        getFieldCondition({ commit }){
            // 清空筛选条件
            commit("setShareFilter", {});
            // 发起请求
            let url = "field/fieldCondition";
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setShareFilter", res.data.result);
                };
            }).catch(() => {});
        },

        // 影像课堂视频列表
        getVideoList({ commit }, arg){
            // 清空数据
            commit("clearList");
            commit("handleLoading", true);
            // 发起请求
            let url = "video/videoList?typeID=" + arg;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 1){
                    commit("writeList", {
                        list: res.data.videos, 
                        page: {}
                    });
                };
            }).catch(() => {});
        },
        getVideoType({ commit }){
            let url = "video/typeList";
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setVideoType", res.data.result);
                }
            }).catch(() => {})
        }
    },
}