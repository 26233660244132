// 广告获取
import axios from '../axios'

export default {
    state: {
        // 轮播广告
        banners: [],

        other_page: "", // 富文本页面内容
    },
    mutations: {
        // 轮播广告写入
        writeBanner(state, arg){
            state.banners = arg;
        },

        // 清空轮播广告
        clearBanner(state){
            state.banners = [];
        },

        setOtherPage(state, arg){ // 写入富文本内容
            state.other_page = arg;
        }
    },
    actions: {
        getMainBanner(context){ // 获取首页轮播图
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "main/banner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
					//数据存储
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        // 影像服务轮播广告
        getPhotographBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "photograph/photographBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        
        // 摄影师轮播广告
        getLensmanBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "lensman/lensmanBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        
        // 化妆师轮播广告
        getDresserBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "dresser/dresserBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        
        // 场地轮播广告
        getFieldBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "field/fieldBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        // 场地详情轮播广告
        getFieldDetailBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "field/fieldDetailBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        
        // 服饰租赁轮播广告
        getClothesBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "lease/leaseBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },
        
        // 影像课堂轮播广告
        getVideoBanner(context){
            // 清空数据
            context.commit("clearBanner");
            // 发起请求
            let url = "video/videoBanner";
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    context.commit("writeBanner", res.data.banners);
                };
            }).catch(() => {});
        },

        getOtherPage({ commit }, arg){ // 获取富文本数据
            let url = "content/article?id=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setOtherPage",res.data.result)
                }
            }).catch(() => {})
        },
    },
}