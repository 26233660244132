import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
	routes: [
		{// 首界面
			path: '/',
			name: 'home',
			component: () => import('./views/interface')
		},
		{// 首页
			path: '/main',
			name: 'main',
			component: () => import('./views/main')
		},
		{// 相机租赁
			path: '/clothes',
			component: () => import('./views/camera'),
			children: [
				{// 默认路由
					path: '',
					name: 'clothes',
					component: () => import('./views/clothesList')
				},
				{// 详情路由
					path: 'detail',
					name: 'clothesDetail',
					component: () => import('./views/cameraDetail')
				},
				{// 支付路由
					path: 'pay',
					name: 'clothesPay',
					component: () => import('./views/orderDetail')
				}
			]
		},
		{// 预约拍照
			path: '/photograph',
			component: () => import('./views/photograph'),
			children: [
				{// 默认路由
					path: '',
					name: 'photograph',
					component: () => import('./views/photographList')
				},
				{// 详情路由
					path: 'detail',
					name: 'photographDetail',
					component: () => import('./views/photographDetail')
				},
				{// 支付路由
					path: 'pay',
					name: 'photographPay',
					component: () => import('./views/orderDetail')
				}
			]
		},
		{// 摄影师
			path: '/lensman',
			component: () => import('./views/lensman'),
			children: [
				{// 默认路由
					path: '',
					name: 'lensman',
					component: () => import('./views/lensmanList')
				},
				{// 详情路由
					path: 'detail',
					name: 'lensmanDetail',
					component: () => import('./views/lensmanDetail')
				},
				{// 服务类型
					path: 'service',
					name: 'lensmanService',
					component: () => import('./views/memberService')
				},
				{// 从业者订单支付页
					path: 'pay',
					name: 'memberPay',
					component: () => import('./views/memberPay')
				},
				{// 从业者订单详情页
					path: 'order',
					name: 'memberOrder',
					component: () => import('./views/memberOrder')
				}
			]
		},
		{// 化妆师
			path: '/dresser',
			component: () => import('./views/dresser'),
			children: [
				{// 默认路由
					path: '',
					name: 'dresser',
					component: () => import('./views/dresserList')
				},
				{// 详情路由
					path: 'detail/',
					name: 'dresserDetail',
					component: () => import('./views/dresserDetail')
				},
				{// 服务类型
					path: 'service',
					name: 'dresserService',
					component: () => import('./views/memberService')
				}
			]
		},
		{// 场地
			path: '/field',
			component: () => import('./views/field'),
			children: [
				{// 默认路由
					path: '',
					name: 'field',
					component: () => import('./views/fieldList')
				},
				{// 详情路由
					path: 'detail',
					name: 'fieldDetail',
					component: () => import('./views/fieldOrder')
				},
				{// 支付路由
					path: 'pay',
					name: 'fieldPay',
					component: () => import('./views/orderDetail')
				}
			]
		},
		{// 影像课堂
			path: '/video',
			name: 'video',
			component: () => import('./views/video')
		},
		{
			path: '/video/detail',
			name: 'videoDetail',
			component: () => import('./views/videoDetail')
		},
		{// 摄影器材
			path: '/equipment',
			component: { render: (h) => h('router-view') },
			children: [
				{// 首页
					path: '',
					name: 'equipment',
					component: () => import('./views/pageEquipment')
				},
				{// 列表页
					path: 'list',
					name: 'equipmentList',
					component: () => import('./views/equipmentList')
				},
				{
					path: 'detail',
					name: 'equipmentDetail',
					component: () => import('./views/equipmentDetail')
				}
			]
		},
		{// 化妆品
			path: '/cosmetics',
			component: { render: (h) => h('router-view') },
			children: [
				{// 首页
					path: '',
					name: 'cosmetics',
					component: () => import('./views/pageCosmetics')
				},
				{// 列表页
					path: 'list',
					name: 'cosmeticsList',
					component: () => import('./views/cosmeticsList')
				},
				{
					path: 'detail',
					name: 'cosmeticsDetail',
					component: () => import('./views/cosmeticsDetail')
				}
			]
		},
		{// 墙面定制
			path: '/metope',
			component: { render: (h) => h('router-view') },
			children: [
				{// 首页
					path: '',
					name: 'metope',
					component: () => import('./views/pageMetope')
				},
				{// 列表页
					path: 'list',
					name: 'metopeList',
					component: () => import('./views/metopeList')
				},
				{// 详情页
					path: 'detail',
					name: 'metopeDetail',
					component: () => import('./views/metopeDetail')
				},
				{// 订单页
					path: 'pay',
					name: 'metopePay',
					component: () => import('./views/metopePay')
				},
				{// 订单页
					path: 'order',
					name: 'metopeOrder',
					component: () => import('./views/metopeOrder')
				}
			]
		},
		{// 影像制作
			path: '/media',
			component: { render: (h) => h('router-view') },
			children: [
				{// 首页
					path: '',
					name: 'media',
					component: () => import('./views/pageMedia')
				},
				{// 列表页
					path: 'list',
					name: 'mediaList',
					component: () => import('./views/mediaList')
				},
				{
					path: 'detail',
					name: 'mediaDetail',
					component: () => import('./views/mediaDetail')
				}
			]
		},
		{// 登录
			path: '/login',
			name: 'login',
			component: () => import('./views/login')
		},
		{// 注册
			path: '/register',
			name: 'register',
			component: () => import('./views/register')
		},
		{// 个人中心
			path: '/center',
			component: () => import('./views/center'),
			children: [
				{// 默认路由
					path: '',
					redirect: 'basic'
				},
				{// 基本资料
					path: 'basic',
					name: 'basic',
					component: () => import('./views/center/basicData')
				},
				{// 密码修改
					path: 'pwd',
					name: 'pwd',
					component: () => import('./views/center/pwd')
				},
				{// 实名认证
					path: 'identity',
					name: 'identity',
					component: () => import('./views/center/identity')
				},
				{// 校园认证
					path: 'campus',
					name: 'campus',
					component: () => import('./views/center/campusIdentity')
				},
				{// 绑定社交账号
					path: 'social',
					name: 'social',
					component: () => import('./views/center/socialAccounts')
				},
				{// 查看排期
					path: 'scheduling',
					name: 'scheduling',
					component: () => import('./views/center/scheduling')
				},
				{// 摄影师
					path: 'lensman',
					component: { render: (h) => h('router-view') },
					children: [
						{// 默认路由
							path: '',
							redirect: 'info'
						},
						{// 更改 & 新增摄影师路由
							path: 'info',
							name: 'centerLensmanInfo',
							component: () => import('./views/center/lensmanInfo')
						},
						{// 作品编辑路由
							path: 'works',
							name: 'centerLensmanWorks',
							component: () => import('./views/center/memberWorks')
						},
						{// 首席编辑
							path: 'chief',
							name: 'centerLensmanChief',
							component: () => import('./views/center/memberWorks')
						},
						{// 网红编辑
							path: 'celebrity',
							name: 'centerLensmanCelebrity',
							component: () => import('./views/center/memberWorks')
						},
						{// 斜杠编辑
							path: 'slash',
							name: 'centerLensmanSlash',
							component: () => import('./views/center/memberWorks')
						}
					]
				},
				{// 化妆师
					path: 'dresser',
					component: { render: (h) => h('router-view') },
					children: [
						{// 默认路由
							path: '',
							redirect: 'info'
						},
						{// 更改 & 新增摄影师路由
							path: 'info',
							name: 'centerDresserInfo',
							component: () => import('./views/center/dresserInfo')
						},
						{// 作品编辑路由
							path: 'works',
							name: 'centerDresserWorks',
							component: () => import('./views/center/works')
						},
						{// 网红编辑
							path: 'celebrity',
							name: 'centerDresserCelebrity',
							component: () => import('./views/center/memberWorks')
						}
					]
				},
				{// 相机管理
					path: 'camera',
					component: { render: (h) => h('router-view') },
					children: [
						{// 默认路由
							path: '',
							name: 'centerCamera',
							component: () => import('./views/center/cameraList')
						},
						{// 更改 & 新增摄影师路由
							path: 'info',
							name: 'centerCameraInfo',
							component: () => import('./views/center/cameraInfo')
						}
					]
				},
				{// 场地管理 service
					path: 'field',
					component: { render: (h) => h('router-view') },
					children: [
						{// 默认路由
							path: '',
							name: 'centerField',
							component: () => import('./views/center/fieldList')
						},
						{// 更改 & 新增摄影师路由
							path: 'info',
							name: 'centerFieldInfo',
							component: () => import('./views/center/fieldInfo')
						}
					]
				},
				{// 原创作者
					path: 'original',
					component: { render: (h) => h('router-view') },
					children: [
						{// 基本资料
							path: 'data',
							name: 'originalData',
							component: () => import('./views/center/originalData')
						},
						{// 上传模板（模板列表）
							path: 'list',
							name: 'originalList',
							component: () => import('./views/center/originalList')
						},
						{// 模板编辑
							path: 'edit',
							name: 'originalEdit',
							component: () => import('./views/center/originalEdit')
						}
					]
				},
				{// 购买订单
					path: 'ownpurchase',
					name: 'ownpurchase',
					component: () => import('./views/center/ownPurchase')
				},
				{// 租赁订单
					path: 'ownlease',
					name: 'ownlease',
					component: () => import('./views/center/ownLease')
				},
				{// 摄影订单
					path: 'ownphotography',
					name: 'ownphotographyOrder',
					component: () => import('./views/center/ownPhotography')
				},
				{// 场地订单
					path: 'site',
					name: 'site',
					component: () => import('./views/center/site')
				},
				{// 服务订单
					path: 'ownService',
					name: 'ownService',
					component: () => import('./views/center/ownService')
				},
				{// 制作商城订单
					path: 'ownMetope',
					name: 'ownMetope',
					component: () => import('./views/center/ownMetope')
				},
				{// 购买订单
					path: 'purchase',
					name: 'purchase',
					component: () => import('./views/center/purchase')
				},
				{// 租赁订单
					path: 'lease',
					name: 'lease',
					component: () => import('./views/center/lease')
				},
				{// 摄影订单
					path: 'photography',
					name: 'photographyOrder',
					component: () => import('./views/center/photography')
				},
				{// 服务订单
					path: 'service',
					name: 'service',
					component: () => import('./views/center/service')
				},
				{// 商城订单
					path: 'store',
					name: 'store',
					component: () => import('./views/center/store')
				},
				{// 账户余额
					path: 'wallet',
					name: 'wallet',
					component: () => import('./views/center/wallet')
				}
			]
		},
		{// 订单
			path: '/order',
			component: () => import('./views/order'),
			children: [
				{// 默认路由
					path: '',
					redirect: 'lease'
				},
				{// 租赁订单详情页
					path: 'lease',
					name: 'orderLease',
					component: () => import('./views/orderLease')
				},
				{// 摄影订单详情页
					path: 'photography',
					name: 'orderPhotography',
					component: () => import('./views/orderPhotography')
				},
				{// 场地订单详情页
					path: 'site',
					name: 'orderSite',
					component: () => import('./views/orderSite')
				},
				{// 取消订单
					path: 'cancel',
					name: 'orderCancel',
					component: () => import('./views/orderCancel')
				}
			]
		},
		{// 样片
			path: '/sample',
			name: 'sample',
			component: () => import('./views/sample')
		},
		{// 关于我们
			path: '/about',
			name: 'about',
			component: () => import('./views/otherPage')
		},
		{// 联系我们
			path: '/contact',
			name: 'contact',
			component: () => import('./views/otherPage')
		},
		{// 加入我们
			path: '/join',
			name: 'join',
			component: () => import('./views/otherPage')
		},
		{// 发展历程
			path: '/history',
			name: 'history',
			component: () => import('./views/otherPage')
		}
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 }
		}
	}
})
