// 订单模块
import axios from '../axios'
import qs from "qs";

export default {
    state: {
        orderTableList: [],

        order_detail: {}, // 订单信息
        order_status: 0, // 订单状态

        // ************ 样片 ****************
        sample_list: [], // 样片列表
        max_select: 0, // 当前最多可选数量
        sample_status: 0, // 当前样片状态
        order_user: "", // 当前订单所属用户
    },
    mutations: {
        setCenterOrder(state, arg = []) {
            state.orderTableList = arg;
			// console.log("查看过滤出来的数组",state.orderTableList)
        },
        setOrderStatus(state, arg){
            state.order_status = arg;
        },

        // ************ 服饰租赁 ****************
        steLeaseOrder(state, arg){
            if(!!arg.beginTime){
                arg.beginTime = arg.beginTime.split(" ");
                arg.endTime = arg.endTime.split(" ");
                state.order_status = arg.orderStatus;
            }
            state.order_detail = arg;
        },
        // ************ 影像服务 ****************
        setPhotograpOrder(state, arg){
            if(!!arg.beginTime){
                arg.beginTime = arg.beginTime.split(" ");
                arg.endTime = arg.endTime.split(" ");
                state.order_status = arg.orderStatus;
            }
            state.order_detail = arg;
        },
        // ************ 场地租赁 ****************
        setFieldOrder(state, arg){
            if(!!arg.beginTime){
                arg.beginTime = arg.beginTime.split(" ");
                arg.endTime = arg.endTime.split(" ");
                state.order_status = arg.orderStatus;
            }
            state.order_detail = arg;
        },
        // ************ 从业者订单详情 ****************
        setMemberOrder(state, arg = {}) {
            state.order_detail = arg;
        },
        // ************ 样片 ****************
        setSampleList(state, arg){ // 写入样片图片列表
            state.sample_list = arg;
        },
        setSampleMax(state, arg){ // 写入样片最大可选数量
            state.max_select = arg;
        },
        setSampleStatus(state, arg){ // 写入样片状态
            state.sample_status = arg;
        },
        setOrderUser(state, arg){ // 写入订单所属用户
            state.order_user = arg;
        }
    },
    actions: {
        getCenterOrder({ commit }, { url, params }) {
            commit('handleLoading', true);
            axios({
                method: 'POST',
                url,
                data: params
            }).then(res => {
                if(res.data.code == 200){
					// console.log("成功",res.data.result)
					commit("setCenterOrder",res.data.result)
                    commit("handleLoading", true);
                }
            }).catch(err => {
                console.log(`获取订单表格接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },
        // 一、我的订单
        // 1、更改租赁订单状态（/usercenter/leaseOrderStatus）
        // 2、完成摄影订单（/usercenter/finishedselect）
        // 3、更改场地订单（/usercenter/fieldOrderStatus）
        // 二、接单池
        // 1、更改租赁订单状态（/usercenter/leaseOrderStatus）
        // 2、更改摄影订单状态（usercenter/finishedshoot）
        // 3、接单摄影订单（usercenter/accessOrder）
        //
        handleOrderStatus(store, { url, params }) {
            return axios({
                method: 'POST',
                url,
                data: params
            })
        },
        handleAddOrder(store, { url, params }) {
            return axios({
                method: 'POST',
                url,
                data: params
            })
        },
        // ************ 服饰租赁 ****************
        handleWechatPay({}, arg){ // 微信支付二维码获取
            let { type, orderID } = arg,
            url = "/pay/wxpay",
            data = qs.stringify({
                type,
                orderID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    resolve(res.data.result)
                }).catch(() => {})
            })
        },

        // ************ 服饰租赁 ****************
        handleLeaseAddOrder({ rootState, commit }, arg){
            commit("handleLoading", true);
            let { price, goods_id, lease_begin, lease_end, order_type } = arg,
            userID = rootState.user.user_id,
            url = "/lease/leaseorder",
            data = qs.stringify({
                userID,
                price,
                goods_id,
                lease_begin,
                lease_end,
                order_type,
                remarks: ""
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.result.orderID
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        // ************ 服饰租赁-物流单号 ****************
        handleSendTraNo({ rootState, commit }, arg){ // 出借人上传物流单号
            commit('handleLoading', true);
            let { orderID, traNo, status } = arg,
                userID = rootState.user.user_id,
                url = 'usercenter/sendTraNo',
                data = qs.stringify({
                    userID,
                    orderID,
                    traNo,
                    status
                })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then(res => {
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {
                }).finally(() => {
                    commit('handleLoading', false);
                })
            })
        },
        // ************ 影像服务 ****************
        handlePhotograpAddOrder({ rootState, commit }, arg){
            commit("handleLoading", true);
            let { price, theme_id, style_id, space_id, need_dresser, film_type, photography_number, booking_begin } = arg,
            userID = rootState.user.user_id,
            url = "/photograph/bookorder",
            data = qs.stringify({
                userID,
                price,
                theme_id,
                style_id,
                space_id,
                need_dresser,
                film_type,
                photography_number,
                booking_begin
            })
            // console.log(arg);
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: "success",
                            msg: res.data.result.orderID
                        })
                    }else{
                        resolve({
                            type: "error",
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        // ************ 场地租赁 ****************
        handleFieldAddOrder({ rootState, commit }, arg){
            commit("handleLoading", true);
            let { price, field_id, series, lease_begin, lease_end, photography_number } = arg,
            userID = rootState.user.user_id,
            url = "field/fieldorder",
            data = qs.stringify({
                userID,
                price,
                field_id,
                series,
                lease_begin,
                lease_end,
                photography_number,
                remarks: ""
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.result.orderID
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },

        // ************ 摄影订单列表 ****************
        handleUploadLink({ rootState, commit }, arg){ // 摄影师上传下载地址
            commit('handleLoading', true);
            let { orderID, downloadurl, downloadkey } = arg,
            userID = rootState.user.user_id,
            url = 'usercenter/downloadlink',
            data = qs.stringify({
                userID,
                orderID,
                downloadurl,
                downloadkey
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then(res => {
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {
                }).finally(() => {
                    commit('handleLoading', false);
                })
            })
        },

        // ************ 租赁订单详情 ****************
        getLeaseOrderDetail({ rootState, commit }, arg){
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "/usercenter/leaseOrderDetail?userID="+userID+"&orderID="+arg;
            axios(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("steLeaseOrder", res.data.result)
                }
            }).catch(() => {})
        },
        // ************ 摄影订单详情 ****************
        getPhotograpOrderDetail({ rootState, commit }, arg){
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "/usercenter/bookOrderDetail?userID="+userID+"&orderID="+arg;
            axios(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setPhotograpOrder", res.data.result)
                }
            }).catch(() => {})
        },
        // ************ 场地订单详情 ****************
        getFieldOrdFerDetail({ rootState, commit }, arg){
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "/usercenter/fieldOrderDetail?userID="+userID+"&orderID="+arg;
            axios(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setFieldOrder", res.data.result)
                }
            }).catch(() => {})
        },
        // ************ 从业者订单详情 ****************
        getMemberOrderPay({ commit }, url) {
            commit('handleLoading', true)
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMemberOrder', res.data.result)
                }
            }).catch(err => {
                console.log(`获取从业者订单详情接口异常${err}`)
            }).finally(() => {
                commit('handleLoading', false)
            })
        },
        getMemberOrderDetail({ commit }, url) {
            commit('handleLoading', true);
            axios.get(url)
                .then(res => {
                    if(res.data.code == 200){
                        commit('setMemberOrder', res.data.result);
                        commit('setOrderStatus', res.data.result.orderStatus);
                    }
                })
                .catch(err => console.log(`获取从业者订单详情接口异常：${err}`))
                .finally(() => commit('handleLoading', false))
        },

        handleConfirmOrderInfo({ rootState }, arg){ // 订单确认信息
            let { orderID, userName, userPhone, address, type } = arg,
            userID = rootState.user.user_id,
            url = "/photograph/orderinfo",
            data = qs.stringify({
                userID,
                orderID,
                userName,
                userPhone,
                address,
                type
            })
            axios({
                method: "POST",
                url,
                data
            }).then(() => {
            }).catch(() => {})
        },

        // ************ 样片列表获取 ****************
        getSampleList({ rootState, commit }, arg){ // 样片列表获取
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "/usercenter/samplelist?userID=" + userID + "&orderID=" + arg;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setSampleList", res.data.result.samplelist);
                    commit("setSampleMax", res.data.result.maxselect);
                    commit("setSampleStatus", res.data.result.sampleStatus);
                    commit("setOrderUser", res.data.result.userID);
                    commit("setOrderStatus", res.data.result.orderStatus);
                }
            }).catch(() => {})
        },
        handleUploadSample({ rootState, commit }, arg){ // 样片上传
            let { orderID, image, name } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/uploadsample",
            data = qs.stringify({
                orderID,
                image,
                name,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    if(res.data.code == 200){
                        commit("setSampleList", res.data.result.samplelist)
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleSelectSample({ rootState, commit }, arg){ // 提交挑选
            commit("handleLoading", true);
            let { orderID, sampleID } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/sampleselected",
            data = qs.stringify({
                orderID,
                sampleID,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleSampleStatus({ rootState, commit }, arg){ // 编辑样片状态
            commit("handleLoading", true);
            let { orderID, status } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/samplestatus",
            data = qs.stringify({
                orderID,
                status,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleSampleDel({ rootState, commit }, arg){ // 删除样片
            commit("handleLoading", true);
            let { orderID, sampleID } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/deletesample",
            data = qs.stringify({
                orderID,
                sampleID,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setSampleList", res.data.result.samplelist);
                        commit("setSampleMax", res.data.result.maxselect);
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleChoiceDel({ rootState, commit }, arg) {
            commit("handleLoading", true);
            let { orderID, choiceID } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/deletechoice",
            data = qs.stringify({
                orderID,
                choiceID,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setSampleList", res.data.result.choicelist);
                        commit("setSampleMax", res.data.result.maxselect);
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        getChoiceList({ rootState, commit }, arg){ // 精片列表获取
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "/usercenter/choicelist?userID=" + userID + "&orderID=" + arg;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setSampleList", res.data.result.choicelist);
                    commit("setSampleMax", res.data.result.maxselect);
                    commit("setSampleStatus", res.data.result.sampleStatus);
                    commit("setOrderUser", res.data.result.userID);
                    commit("setOrderStatus", res.data.result.orderStatus);
                }
            }).catch(() => {})
        },
        handleUploadChoice({ rootState, commit }, arg){ // 精片上传
            let { orderID, image, name } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/uploadchoice",
            data = qs.stringify({
                orderID,
                image,
                name,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    if(res.data.code == 200){
                        commit("setSampleList", res.data.result.choicelist)
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch((forr) => {
					console.log(forr)
				})
            })
        },
        handleChoiceImage({ rootState, commit }, arg){ // 修改精修照片
            commit("handleLoading", true);
            let { orderID, image, choiceID } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/choiceimage",
            data = qs.stringify({
                orderID,
                image,
                choiceID,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleChoiceRemarks({ rootState, commit }, arg){ // 提交精修留言
            commit("handleLoading", true);
            let { orderID, remarks, choiceID } = arg,
            userID = rootState.user.user_id,
            url = "/usercenter/choiceremarks",
            data = qs.stringify({
                orderID,
                remarks,
                choiceID,
                userID
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        }
    }
}
