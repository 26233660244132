import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios'
import qs from "qs";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

// 导入子模块
import staticData from './modules/staticData' // 页面静态参数
import goodsList from './modules/goodsList' // 商品列表
import goodsDetail from './modules/goodsDetail' // 商品详情
import banner from './modules/banner' // 广告获取
import user from './modules/user' // 用户模块
import order from './modules/order' // 订单模块
import metope from './modules/metope' // 墙面定制
import original from './modules/original' // 原创作者

export default new Vuex.Store({
	state: {
		loading: false, // 加载动画
		dialogVisible: false, // 模态框控制器
		outsideDialog: false, // 外层模态框控制器
		dialogImageUrl: "", // 图片展示模态框地址
	},
	mutations: {
		handleLoading(state, arg){ // 更改加载动画状态
			state.loading = arg
		},
		changeDialogVisible(state,arg){// 更改模态框状态
			state.dialogVisible = arg;
		},
		changeOutsideDialog(state,arg){// 更改外层模态框状态
			state.outsideDialog = arg;
		},
		writeDialogImgUrl(state,arg){// 图片展示模态框地址写入
			state.dialogImageUrl = arg;
		},
	},
	actions: {
		handleUploadImage({ rootState, commit }, arg){ // 上传图片
			commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "usercenter/uploadImage",
            data = qs.stringify({
                userID,
                image: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message,
                        result: res.data.result
                    })
                }).catch(() => {})
            })
		},
	},
	modules: {
		staticData, // 页面静态参数
		goodsList, // 商品列表
		goodsDetail, // 商品详情
		banner, // 广告
		user, // 用户
		order, // 订单
		metope, // 墙面定制
		original
	},
	plugins: [createPersistedState({
		storage: {
			getItem: key => sessionStorage.getItem(key),
			setItem: (key, value) => sessionStorage.setItem(key, value),
			removeItem: key => sessionStorage.removeItem(key)
		},
		paths: [ "user.is_login", "user.user_id", "user.user_name", "user.user_type", "user.user_level", "order.photography_order" ],
	})]
})
