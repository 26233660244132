// 商品详情数据
import axios from '../axios'

export default {
    state: {
        // 商品图片列表
        imgs: [],
        // 中图片参数
        mdImg: "",
        // 选中小图片下标
        active_index: 0,
        // 商品信息
        info: {},

        // 个人资料
        personal_data: {},
        // 作品
        works: [],
        // 评价
        evaluate: {
            all_rate: "",
            list: [],
        },
        // 联系方式
        contact: {},

        serviceList: [],
        currentPage: 1,
        pageCount: 1,
        
        serviceDetail: {}
    },
    mutations: {
        // 商品信息写入
        setInfo(state, { imgs = [], detail = {} }){
            state.imgs = imgs;
            if(imgs.length>0) state.mdImg = imgs[0];
            state.info = detail;
        },
        // 中图片参数切换
        changeMdImg(state, arg){
            state.mdImg = arg.md;
            state.active_index = arg.index;
        },

        // 个人资料写入
        setPersonalData(state, arg){
            let {
                personal_data = {},
                works = [],
                evaluate = {
                    all_rate: "",
                    list: [],
                },
                contact = {}
            } = arg;
            
            state.personal_data = personal_data;
            state.works = works;
            state.evaluate = evaluate;
            state.contact = contact;
        },
        setMemberServiceList(state, arg) {
            let {
                current_page = 1,
                page_count = 1,
                list = []
            } = arg;

            state.serviceList = list;
            state.currentPage = current_page;
            state.pageCount = page_count;
        },
        setMemberServiceDetail(state, arg) {
            state.serviceDetail = arg;
        },

        // 清空数据
        clearInfo(state){
            state.imgs = [];
            state.mdImg = "";
            state.info = {};
            state.active_index = 0;
        },
        // 清空个人资料数据
        clearPersonalData(state){
            state.personal_data = {};
            state.works = [];
            state.evaluate = {
                all_rate: "",
                list: [],
            };
            state.contact = {};
        },
    },
    actions: {
        // 相机详情
        getCameraDetail({ commit }, arg){
            // 清除数据
            commit("setInfo", {});
            commit('handleLoading', true);
            // 发起请求
            let url = "lease/leaseDetail?goodsID="+arg;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setInfo", res.data);
                };
            }).catch(err => {
                console.log(`服饰详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },

        // 影像服务详情
        getPhotographDetail({ rootState, commit }, arg){
            // 清除数据
            commit("setInfo", {});
            commit('handleLoading', true);
            let { theme_id, style_id, space_id, film_type, need_dresser, photography_number } = arg,
            userID = rootState.user.user_id,
            // 发起请求
            url = `photograph/photographDetail?themeID=${theme_id}&userID=${userID}&style_id=${style_id}&space_id=${space_id}&film_type=${film_type}&need_dresser=${need_dresser}&photography_number=${photography_number}`;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setInfo", res.data);
                };
            }).catch(err => {
                console.log(`影像服务详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },

        // 摄影师详情
        getLensmanDetail({ commit }, arg){
            // 清空数据
            commit("setPersonalData", {});
            commit('handleLoading', true);
            // 发起请求
            let url = "lensman/lensmanDetail?id=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setPersonalData",res.data);
                };
            }).catch(err => {
                console.log(`摄影师详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },
        getMemberServiceList({ commit }, arg) {
            let url = 'ushop/goodsList?userID=' + arg;
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMemberServiceList', res.data.result);
                }
            }).catch(err => console.log(`摄影师详情接口异常：${err}`))
        },
        getMemberServiceDetail({ commit }, { userID, goodsID }) {
            let url = `ushop/goodsDetail?userID=${userID}&goodsID=${goodsID}`;
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMemberServiceDetail', res.data.result);
                }
            }).catch(err => console.log(`摄影师详情接口异常：${err}`))
        },

        // 化妆师详情
        getDresserDetail({ commit }, arg){
            // 清空数据
            commit("setPersonalData", {});
            commit('handleLoading', true);
            // 发起请求
            let url = "dresser/dresserDetail?id=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setPersonalData",res.data);
                };
            }).catch(err => {
                console.log(`化妆师详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },

        // 场地详情
        getFieldDetail({ commit }, arg){
            // 清空数据
            commit("setInfo", {});
            commit('handleLoading', true);
            // 发起请求
            let url = "field/fieldDetail?fieldID=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setInfo", {
                        imgs: res.data.field.album,
                        detail: res.data.field
                    });
                };
            }).catch(err => {
                console.log(`场地详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },

        // 视频详情
        getVideoDetail({ commit }, arg){
            // 清空数据
            commit("setInfo", {});
            commit('handleLoading', true);
            // 发起请求
            let url = "video/videoDetail?videoID=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 1){
                    commit("setInfo", {
                        imgs: [],
                        detail: res.data.video
                    });
                };
            }).catch(err => {
                console.log(`视频详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },
    },
}