import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import URL from '@/tool/url'
Vue.prototype.$_url_ = URL

// 轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)


import 'vue-area-linkage/dist/index.css'
import { pca, pcaa } from 'area-data';
import VueAreaLinkage from 'vue-area-linkage'
Vue.prototype.$pca = pca;
Vue.prototype.$pcaa = pcaa;
Vue.use(VueAreaLinkage)

// 日历控件导入
// import Calendar from 'himmas-vue-calendar'
// Vue.use(Calendar)

// 播放器插件导入
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.config.productionTip = false

Vue.prototype.$qs = qs;

axios.defaults.withCredentials = false;
axios.defaults.baseURL = URL.baseUrl;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$http = axios;

// ui库注册
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
