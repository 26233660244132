// 墙面定制
import axios from '../axios'
import qs from "qs";

export default {
    state: {
        metopeList: [], // 墙面定制列表数据

        listCount: 1, // 墙面定制列表页商品总数
        page: {}, // 墙面定制列表页页码参数

        hotWords: [], // 热门搜索词
        filterStyle: [], // 风格列表
        filterType: [], // 分类列表

        metopeDetail: {}, // 墙面定制详情
        metopeImages: [], // 墙面定制图片数组
        metopeOwner: {}, // 墙面定制发布者信息
        specList: [], // 规格列表
        priceList: [], // 价格列表

        metopeOrder: {} // 墙面定制订单信息
    },
	//进行赋值
    mutations: {
        setMetopeList(state, arg) { // 设置墙面定制列表
            state.metopeList = arg;
        },
        setMetopePageList(state, { list_count = 1, page = {} }) { // 设置墙面定制列表页其它参数
            state.listCount = list_count;
            state.page = page;
        },
        setMetopeFilters(state, { hotwords = [], style = [], type = [] }) {
            state.hotWords = hotwords;
            state.filterStyle = style;
            state.filterType = type;
        },
        setMetopeDetail(state, { detail = {}, img = [], own = {}, spec = [], price = [] }) {
            state.metopeDetail = detail;
            state.metopeImages = img;
            state.metopeOwner = own;
            state.specList = spec;
            state.priceList = price
        },
        setMetopeOrder(state, arg){ // 写入墙面定制订单信息
            state.metopeOrder = arg;
        }
    },
    actions: {
        getPageMetope({ commit }, { search = '', type = '', style = '' }) { // 获取墙面定制首页商品列表
            commit("handleLoading", true);
            let url = 'GetTheHomePage/Customdesigned?search=' + search + '&type=' + type + '&style=' + style;
            axios.get(url).then(res => {
                if (res.data.code == 200) {
                    commit('setMetopeList', res.data.result.wall_list)
                }
            }).catch(error => {
                console.log(`墙面定制首页接口异常：${error}`)
            }).finally(() => commit("handleLoading", false))
        },
        getMetopeList({ commit }, { search = '', type = '', style = '', sort = '', page = 1 }) { // 获取墙面定制列表页
            commit("handleLoading", true);
            let url = 'ListPage/ListPage?search=' + search + '&type=' + type + '&style=' + style + '&sort=' + sort + '&page=' + page;
            axios.get(url).then(res => {
                if (res.data.code == 200) {
                    commit('setMetopeList', res.data.result.wall_list)
                    commit('setMetopePageList', res.data.result)
                }
            }).catch(error => {
                console.log(`获取墙面定制列表页接口异常：${error}`)
            }).finally(() => commit("handleLoading", false))
        },
        getMetopeFilters({ commit }, id = '') { // 获取墙面定制筛选条件
            let url = 'ListOfFilters/Filters?typeID='+id;
            axios.get(url).then(res => {
                if (res.data.code == 200) {
					// console.log(res.data.result)
                    commit('setMetopeFilters', res.data.result)
					
                }
            }).catch(error => {
                console.log(`获取墙面定制筛选条件接口异常：${error}`)
            })
        },
        getMetopeDetail({ commit }, id) { // 获取墙面定制详情
            commit("handleLoading", true);
            let url = 'DetailsPage/DetailsPage?id=' + id;
            axios.get(url).then(res => {
                if (res.data.code == 200) {
                    commit('setMetopeDetail', {
                        detail: res.data.result.wall_detail,
                        img: res.data.result.wall_detail.imgs,
                        own: res.data.result.owner,
                        spec:res.data.result.specList,
                        price: res.data.result.priceList
                    })
                }
            }).catch(error => {
                console.log(`获取墙面定制详情接口异常：${error}`)
            }).finally(() => commit("handleLoading", false))
        },
        handleAddMetopeOrder({ commit }, {userID,id,count,spec1,spec2,price}) { // 墙面定制下单
            commit("handleLoading", true);
            let url = 'CustomizationOrder/order',
            data = qs.stringify({
                userID,
                id,
                count,
                spec1,
                spec2,
				price
            })
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message,
                            orderID: res.data.result.orderID
                        })
                    }else{
                        console.log(`墙面定制下单接口异常，状态码为：${res.data.code}`)
                    }
                }).catch(err => {
                    console.log(`墙面定制下单接口异常：${err}`)
                }).finally(() => commit("handleLoading", false))
            })
        },
        getMetopeOrder({ commit }, id){ // 获取墙面订单信息
            commit("handleLoading", true);
            let url = 'OrderInformation/OrderInformation?id=' + id;
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMetopeOrder', res.data.result)
                }
            }).catch(err => {
                console.log(`获取墙面订单信息接口异常：${err}`)
            }).finally(() => commit("handleLoading", false))
        },
        handleSubmitMetopeOrder({ commit }, arg){ // 提交墙面定制订单信息
            commit("handleLoading", true);
            let {
                orderID,
                know_size,
                size,
                name,
                phone,
                city,
                address,
                remark
            } = arg,
            url = 'SubmitOrder/OrderInformation',
            data = qs.stringify({
                orderID,
                know_size,
                size,
                name,
                phone,
                city,
                address,
                remark
            })
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message,
                        })
                    }
                }).catch(err => {
                    console.log(`提交墙面定制订单信息接口异常：${err}`)
                }).finally(() => commit("handleLoading", false))
            })
        }
    },
}