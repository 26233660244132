<template>
  <div
    id="app"
    v-loading.fullscreen.lock="loading"
    element-loading-text="Loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <jxw-header></jxw-header>
    <div class="content-box">
      <router-view />
    </div>
    <jxw-footer
      v-if="
        $route.path != '/login' &&
          $route.path != '/register' &&
          $route.path != '/'
      "
    ></jxw-footer>
    <el-backtop :visibility-height="300"> </el-backtop>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    loading: (state) => state.loading, // 加载动画
  }),
  components: {
    jxwHeader: () => import("@/components/header.vue"),
    jxwFooter: () => import("@/components/footer.vue"),
  },
};
</script>

<style>
* {
  font-family: "微软雅黑";
  font-size: 14px;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
a:active {
  text-decoration: none !important;
}
a:visited {
  text-decoration: none !important;
}
a:link {
  text-decoration: none !important;
}
ul {
  list-style: none;
  margin: 0;
}
p,
dl,
dd {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.content-box {
  min-height: 100%;
  padding-top: 80px;
}

/* bootstrap */
input[type="file"] {
  display: none;
}
/* 图片加载失败 */
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f7fa;
}
.image-slot > i {
  font-size: 30px;
  color: #909399;
}
/* 列表页分页器容器 */
.page-box {
  padding: 50px 0;
  text-align: right;
}
/* 评分组件 */
.el-icon-star-on {
  font-size: 18px;
}
</style>
