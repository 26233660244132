// 用户模块
import axios from '../axios'
import qs from "qs";

export default {
    state: {
        is_login: false,
        user_id: null,
        user_name: null,
        user_type: null,
        user_level: null,
        // ************ 基本资料 ****************
        basic_data: {}, // 基本资料
        // ************ 实名认证 ****************
        certification: {}, // 认证资料
        // ************ 我的资源 ****************
        resource: {},
        work_list: [], // 作品列表
        work_detail: {},
        work_style: {}, // 作品列表
        member_works: [], // 作品
        // ************ 服饰管理 ****************
        lease_list: [], // 服饰列表
        lease_detail: {
            goods_address: "",
            goods_city: "",
            position: {}
        }, // 服饰详情
        // ************ 账单明细 ****************
        wallet_list: [],
		//摄影师 过滤条件 风格与距离
		condition_styleDistance:{},

    },
    mutations: {
        // ************ 登录注册 ****************
        setLogin(state, arg){
            state.is_login = true;
            state.user_id = arg.userID;
            state.user_name = arg.userName;
            state.user_type = arg.type;
            state.user_level = arg.level;
        },
        clearUser(state){
            state.is_login = false;
            state.user_id = null;
            state.user_name = null;
            state.user_type = null;
            state.user_level = null;
        },
        // ************ 基本资料 ****************
        setBasicData(state, arg){
            state.basic_data = arg;
        },
        setAvatar(state, arg){
            state.basic_data.head = arg;
        },
        // ************ 实名认证 ****************
        setCertification(state, arg){ // 写入实名认证资料
            state.certification = arg;
        },
        // ************ 摄影师 ****************
        setResource(state, arg){
            state.resource = arg;
        },
        setWorksStyle(state, arg){ // 写入摄影师、化妆师作品风格认证资料
            if(JSON.stringify(arg) == "{}"){
                state.work_style = {
                    address: "",
                    serviceArea: "",
                    position: {}
                }
            }else{
                state.work_style = arg;
            }
        },
        setMemberWorks(state, arg){ // 写入作品
            state.member_works = arg;
        },
        setInfoChange(state, arg){ // 修改原始参数
            state.resource.address = arg.address || "";
            if(arg.service) state.resource.serviceArea = arg.service;
            if(arg.position) state.resource.position = arg.position;
        },
        setMemberGoodsList(state, arg){
            state.work_list = arg;
        },
        setMemberGoodsDetail(state, arg){
            state.work_detail = arg;
        },
        // ************ 服饰管理 ****************
        setLeaseList(state, arg){
            state.lease_list = arg;
        },
        setLeaseDetail(state, arg){
            state.lease_detail = arg;
        },
        setLeaseDetailChange(state, arg){
            state.lease_detail.goods_address = arg.address || "";
            if(arg.service) state.lease_detail.goods_city = arg.service;
            if(arg.position) state.lease_detail.position = arg.position;
        },
        // ************ 账单明细 ****************
        setWalletList(state, arg){
            state.wallet_list = arg;
        },
		//摄影师过滤列表
		setCondition(state,arg){
			state.condition_styleDistance = arg.data.result

		}
    },
    actions: {
		getCondition({ commit }) {
		    axios({
		        method: 'GET',
		        url:'/usercenter/bookorderFilter',
		    }).then(res => {
		        if(res.data.code == 200){
					commit("setCondition", res);
				}
		    }).catch(err => {
		        console.log(`获取订单表格接口异常：${err}`)
		    });
		},
        // ************ 登录注册 ****************
        sendCode({ commit }, arg){ // 发送短信验证码
            commit("handleLoading", true);
            return new Promise((resolve) => {
                let url = "auth/smscode?phone=" + arg;
                axios.get(url).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve(res.data.message);
                    }
                }).catch(() => {})
            })
        },
        postRegister({ commit }, arg){ // 注册事件
            commit("handleLoading", true);
            return new Promise((resolve) => {
                let { phone, type, name, password, smscode } = arg,
                url = "auth/register",
                data = qs.stringify({
                    type,
                    phone,
                    name,
                    password,
                    smscode
                })
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: "success",
                            msg:res.data.message
                        });
                    }else{
                        resolve({
                            type: "error",
                            msg:res.data.message
                        });
                    }
                }).catch(() => {})
            })
        },
        postLogin({ commit }, arg){ // 登录
            commit("handleLoading", true);
            return new Promise((resolve) => {
                let { phone, password } = arg,
                url = "auth/login",
                data = qs.stringify({
                    phone,
                    password
                })
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setLogin", res.data.result);
                        resolve({
                            type: "success",
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: "error",
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleForget({ commit }, arg){ // 忘记密码
            commit("handleLoading", true);
            return new Promise((resolve) => {
                let { phone, password, smscode } = arg,
                url = "auth/resetpwd",
                data = qs.stringify({
                    phone,
                    password,
                    smscode
                })
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {})
            })
        },
        postLogout({ commit }){ // 注销
            commit("handleLoading", true);
            return new Promise((resolve) => {
                let url = "auth/logout";
                axios({
                    method: "POST",
                    url
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("clearUser")
                        resolve(res.data.message)
                    }
                }).catch(() => {})
            })
        },
        // ************ 基本资料 ****************
        getBasicData({ state, commit }){
            // commit("handleLoading", true);
            let url = "/usercenter/userinfo?userID="+state.user_id;
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setBasicData", res.data.result);
                    // commit("handleLoading", false);
                }
            }).catch(() => {})
        },
        handleChangeBasic({ state, dispatch, commit }, arg){ // 修改资料
            commit("handleLoading", true);
            let {
                nickname,
                city,
                address,
                born,
                sex,
                phone,
                email,
                wechat,
                qq,
                a_wechat,
                a_alipay,
                a_name,
                a_card,
                a_bank
            } = arg,
            userID = state.user_id,
            url = "/usercenter/userinfo",
            data = qs.stringify({
                userID,
                nickname,
                city,
                address,
                born,
                sex,
                phone,
                email,
                wechat,
                qq,
                a_wechat,
                a_alipay,
                a_name,
                a_card,
                a_bank
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: "success",
                            msg: res.data.message
                        })
                        dispatch("getBasicData")
                    }else{
                        resolve({
                            type: "error",
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleChangeAvatar({ state, commit }, arg){
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "/usercenter/userinfo",
            data = qs.stringify({
                userID,
                head: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setAvatar", res.data.result.head)
                        resolve({
                            type: "success",
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: "error",
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        // ************ 实名认证 ****************
        getRealname({ state, commit }){ // 获取实名认证
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/realname?userID=" + userID;
            axios(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setCertification", res.data.result)
                }
            }).catch(() => {})
        },
        handleSubmitRealname({ state, commit }, arg){ // 提交实名认证
            commit("handleLoading", true);
            let { name, idcard, idcardPic } = arg,
            userID = state.user_id,
            url = "usercenter/realname",
            data = qs.stringify({
                userID,
                name,
                idcard,
                idcardPic
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {})
            })
        },
        getCertification({ state, commit }){ // 获取校园认证
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/certification?userID=" + userID;
            axios(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setCertification", res.data.result)
                }
            }).catch(() => {})
        },
        handleSubmitCertification({ state, commit }, arg){ // 提交校园认证
            commit("handleLoading", true);
            let { name, campus, enrol, stucard } = arg,
            userID = state.user_id,
            url = "usercenter/certification",
            data = qs.stringify({
                userID,
                name,
                campus,
                enrol,
                stucard
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {})
            })
        },
        // ************ 修改密码 ****************
        handleChangePwd({ state, commit }, arg){ // 修改密码
            commit("handleLoading", true);
            let { password, newPassword } = arg,
            userID = state.user_id,
            url = "/usercenter/password",
            data = qs.stringify({
                userID,
                password,
                newPassword
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: "success",
                            msg: res.data.message
                        })
                    }else{
                        resolve({
                            type: "error",
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        // ************ 摄影师 ****************
        getPhotographerInfo({ state, commit }){ // 获取摄影师信息
            commit("setResource", {})
            let userID = state.user_id,
            url = "usercenter/photographer?userID=" + userID;
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setResource", res.data.result)
                }
            }).catch(() => {})
        },
        handlePhotographerAvatar({ state, commit }, arg){ // 修改摄影师头像
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "/usercenter/photographer",
            data = qs.stringify({
                userID,
                head: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.result.head
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handlePhotographerCertphoto({ state, commit }, arg){ // 修改摄影师学生证
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "/usercenter/photographer",
            data = qs.stringify({
                userID,
                certphoto: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message,
                            link: res.data.result.certphoto
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleModifyPhotographer({ state, commit, dispatch }, arg){ // 修改摄影师信息
            commit("handleLoading", true);
            let { goodat, certphoto, serviceArea, address, briefInfo, position } = arg,
            userID = state.user_id,
            url = "/usercenter/photographer",
            data = qs.stringify({
                userID,
                goodat,
                certphoto,
                serviceArea,
                address,
                briefInfo,
                position: position.lat + "," + position.lng
            },
            {
                arrayFormat: 'brackets'
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                        dispatch("getPhotographerInfo")
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        getMemberGoodsList({ commit }, { userID, tagID }) { // 获取个人中心-摄影师标签页内商品列表
            commit("handleLoading", true);
            let url = `usercenter/goodsList?userID=${userID}&tagID=${tagID}`;
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMemberGoodsList', res.data.result.list)
                }
            }).catch(err => {
                console.log(`获取从业者商品列表接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },
        getMemberGoodsDetail({ commit }, { userID, goodsID }){
            commit("handleLoading", true);
            let url = `usercenter/goodsDetail?userID=${userID}&goodsID=${goodsID}`;
            axios.get(url).then(res => {
                if(res.data.code == 200){
                    commit('setMemberGoodsDetail', res.data.result)
                }
            }).catch(err => {
                console.log(`获取从业者商品详情接口异常：${err}`)
            }).finally(() => commit('handleLoading', false));
        },
        deleteMemberGoods({ commit }, { userID, goodsID }) {
            commit("handleLoading", true);
            let url = 'usercenter/deleteGoods',
            data = qs.stringify({
                userID,
                goodsID
            })
            return new Promise(resolve => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(err => {
                    console.log(`获取从业者商品详情接口异常：${err}`)
                }).finally(() => commit('handleLoading', false));
            })
        },
        editMemberGoods({ commit }, arg) { // 上传从业者商品详情
            commit("handleLoading", true);
            let {
                userID,
                goodsID,
                tagID,
                openBegin,
                openEnd,
                goodsType,
                name,
                skin,
                detail,
                sketch,
                area,
                address,
                position,
                price,
                unit,
                url
            } = arg,
                data = qs.stringify(
                    {
                        userID,
                        goodsID,
                        tagID,
                        openBegin,
                        openEnd,
                        goodsType,
                        name,
                        skin,
                        detail,
                        sketch,
                        area,
                        address,
                        position,
                        price,
                        unit
                    },
                    {
                        arrayFormat: 'brackets'
                    }
                );
            return new Promise(resolve => {
                axios({
                    method: 'POST',
                    url,
                    data
                }).then(res => {
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(err => {
                    console.log(`上传从业者商品详情接口异常：${err}`)
                }).finally(() => commit('handleLoading', false));
            })
        },
        // ************ 化妆师 ****************
        getDresserInfo({  state, commit }){
            commit("setResource", {})
            let userID = state.user_id,
            url = "/usercenter/dresser?userID=" + userID;
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setResource", res.data.result)
                }
            }).catch(() => {})
        },
        handleDresserAvatar({  state, commit }, arg){ // 修改化妆师头像
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "/usercenter/dresser",
            data = qs.stringify({
                userID,
                head: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.result.head
                        })
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        handleModifyDresser({  state, commit, dispatch }, arg){ // 修改化妆师信息
            commit("handleLoading", true);
            let { goodat, certphoto, serviceArea, address, briefInfo, position } = arg,
            userID = state.user_id,
            url = "/usercenter/dresser",
            data = qs.stringify({
                userID,
                goodat,
                certphoto,
                serviceArea,
                address,
                briefInfo,
                position: position.lat + "," + position.lng
            },
            {
                arrayFormat: 'brackets'
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        resolve({
                            type: true,
                            msg: res.data.message
                        })
                        dispatch("getDresserInfo")
                    }else{
                        resolve({
                            type: false,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        },
        getWorksStyle({ state, commit }, arg){ // 获取摄影师/化妆师作品风格认证资料
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "/usercenter/worksstyle?userID=" + userID + "&type=" + arg;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setWorksStyle", res.data.result);
                }
            }).catch(() => {})
        },
        handleSaveWorksStyle({ state, commit }, arg){ // 保存摄影师/化妆师风格认证资料
            commit("handleLoading", true);
            let { type, styleIDs, serviceArea, briefInfo, address, position } = arg,
            userID = state.user_id,
            url = "/usercenter/worksstyle",
            data = qs.stringify({
                userID,
                type,
                styleIDs,
                serviceArea,
                briefInfo,
                address,
                position: position.lat + "," + position.lng
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {})
            })
        },
        getMemberWorks({ state, commit }, arg){ // 获取成员作品
            let userID = state.user_id,
            url = "/usercenter/getcertworks?userID=" + userID + "&type=" + arg;
            axios.get(url).then((res) => {
                if(res.data.code == 200){
                    commit("setMemberWorks", res.data.result)
                }
            }).catch(() => {})
        },
        handleUploadWorks({ state, commit }, arg){ // 上传作品
            let { type, styleID, image, mid } = arg,
            userID = state.user_id,
            url = "/usercenter/uploadcertworks",
            data = qs.stringify({
                userID,
                type,
                styleID,
                mid,
                image
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                    if(res.data.code == 200){
                        commit("setMemberWorks", res.data.result)
                    }
                }).catch(() => {})
            })
        },
        // ************ 服饰管理 ****************
        getLeaseList({ state, commit }){ // 获取服饰列表
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/leaseList?userID=" + userID;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setLeaseList", res.data.result)
                }
            }).catch(() => {})
        },
        getLeaseDetail({ state, commit }, arg){ // 获取服饰详情
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/leaseDetail?userID=" + userID + "&goodsID=" + arg;
            axios.get(url).then((res) => {
                commit("handleLoading", false);
                if(res.data.code == 200){
                    commit("setLeaseDetail", res.data.result)
                }
            }).catch(() => {})
        },
        handleLeaseDel({ state, commit }, arg){ // 删除服饰
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/deleteLease",
            data = qs.stringify({
                userID,
                goodsID: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setLeaseList", res.data.result)
                    }
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(() => {})
            })
        },
        handleUploadLeasePic({ state, commit }, arg){ // 上传服饰图片
            commit("handleLoading", true);
            let userID = state.user_id,
            url = "usercenter/uploadImage",
            data = qs.stringify({
                userID,
                image: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setLeaseList", res.data.result)
                    }
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message,
                        result: res.data.result
                    })
                }).catch(() => {})
            })
        },
        handleAddLease({ state, commit }, arg){ // 添加服饰
            commit("handleLoading", true);
            let { goodsName, skin, images, bwh, size, linkType, phone, style, deposit, price, city, address, synopsis, detail, showStatus, position, order_type } = arg,
            userID = state.user_id,
            url = "usercenter/addLease",
            data = qs.stringify(
                {
                    userID,
                    goodsName,
                    skin,
                    images,
                    bwh,
                    size,
                    linkType,
                    phone,
                    style,
                    deposit,
                    price,
                    city,
                    address,
                    synopsis,
                    detail,
                    showStatus,
                    position:  position.lat + "," + position.lng,
                    order_type,
                },
                {
                    arrayFormat: 'brackets'
                }
            )
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    if(res.data.code == 200){
                        commit("setLeaseList", res.data.result)
                    }
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(err => {
                    console.log(`添加服饰详情接口异常：${err}`)
                }).finally(() => {commit('handleLoading', false);})
            })
        },
        handleChangeLease({ state, commit }, arg){ // 编辑服饰
            commit("handleLoading", true);
            let {
                goodsName,
                goodsID,
                skin,
                images,
                bwh,
                size,
                linkType,
                phone,
                style,
                deposit,
                price,
                sellPrice,
                city,
                address,
                synopsis,
                detail,
                showStatus,
                rentSale,
                position,
                order_type,
            } = arg,
            userID = state.user_id,
            url = "usercenter/editLease",
            data = qs.stringify({
                userID,
                goodsName,
                goodsID,
                skin,
                images,
                bwh,
                size,
                linkType,
                phone,
                style,
                deposit,
                price,
                sellPrice,
                city,
                address,
                synopsis,
                detail,
                showStatus,
                rentSale,
                position:  position.lat + "," + position.lng,
                order_type,
            },
            {
                arrayFormat: 'brackets'
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setLeaseList", res.data.result)
                    }
                    resolve({
                        type: res.data.code == 200,
                        msg: res.data.message
                    })
                }).catch(err => {
                    console.log(`编辑服饰详情接口异常：${err}`)
                }).finally(() => commit('handleLoading', false))
            })
        },
        // ************ 账单明细 ****************
        getWalletList({ commit }, {url, params}){
            commit('handleLoading', true);
            axios({
                method: 'POST',
                url,
                data: params
            }).then((res) => {
                if(res.data.code == 200){
                    commit('setWalletList', res.data.result)
                }
            }).catch(() => {
            }).finally(() => {
                commit('handleLoading', false);
            })
        },
        handleWithdraw({ rootState, commit }, arg){ // 提现
            commit("handleLoading", true);
            let userID = rootState.user.user_id,
            url = "usercenter/withdraw",
            data = qs.stringify({
                userID,
                money: arg
            })
            return new Promise((resolve) => {
                axios({
                    method: "POST",
                    url,
                    data
                }).then((res) => {
                    commit("handleLoading", false);
                    if(res.data.code == 200){
                        commit("setBasicData", res.data.result);
                        resolve({
                            type: res.data.code == 200,
                            msg: res.data.message
                        })
                    }
                }).catch(() => {})
            })
        }
    },
}
