export default {
    state: {
        centerPersonalTabs: [], // 个人中心-我的订单-个人标签页参数
        centerSellerTabs: [], // 个人中心-我的订单-商户标签页参数
    },
    mutations: {
        // 写入标签页参数
        writeCenterOrderTabs(state, arg){
            // 个人
            state.centerPersonalTabs = arg.personal;
            // 商户
            state.centerSellerTabs = arg.seller;
        },
        // 清除标签页参数
        clearCenterOrderTabs(state){
            state.centerPersonalTabs = [];
            state.centerSellerTabs = [];
        },
    },
}