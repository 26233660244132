export default {
    // baseUrl: 'http://192.168.1.117:9999/jx/apiv2/',
    // baseUrl: 'http://192.168.1.126:9999/jx/apiv2/',
	//线上地址
    // baseUrl: 'http://www.ijustsharing.com/jx/apiv2/',
	baseUrl: 'https://www.ijustsharing.com/jx/apiv2/',
	//本地地址
	// baseUrl: 'http://127.0.0.1:9998/jx/apiv2/',

}
